.App {
  text-align: center;
  overflow-x: hidden;
  animation: fade-in 3s;

  .page-wrapper {
    .studios {
      background-image: url('./images/logo.jpg');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: wrap;
      text-align: left;
      padding: 20px 50px;
      
      @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(auto, 1fr, auto);
        gap: 40px;
        padding: 40px 100px;
      }
    }

    .large-button-wrapper {
      margin: 40px 0;

      .large-button {
        font-family: "Indivisible-Regular";
        background-color: #fff;
        text-decoration: none;
        color: #000;
        padding: 10px 30px;
        align-items: center;
        border: 1px solid #000;
        cursor: pointer;
        transition: background-color 0.3s ease, color 0.3s ease;

        @media screen and (min-width: 768px) {
          padding: 10px 200px;
        }
      }

      .large-button:hover {
          background-color: #000;
          color: #fff;
          border: 1px solid #fff;
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}
