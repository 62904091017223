@import "../../styles/fonts.scss";

.studio {
    .title1 {
        font-size: 36px;
        font-family: "Indivisible-Regular";
        margin: 0px;

        @media screen and (min-width: 768px) {
            font-size: 50px;
        }
    }
    
    .title2 {
        font-size: 36px;
        font-family: "Indivisible-Bold";
        margin: 0px;

        @media screen and (min-width: 768px) {
            font-size: 50px;
        }
    }
}

