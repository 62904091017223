.header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-around;
  background-color: #000000;
  width: 100%;
  height: 100px;

  .whitespace-image {
    display: none;
  }

  @media (min-width: 768px) {
    .whitespace-image {
      display: block;
    }
  }
}
