@import "../../styles/fonts.scss";

.barber-content {
    display: flex;
    margin-top: 30px;

    .image {
        width: 100px;
        height: 100px;
        border-radius: 100px;
        border: 1px solid black;

        @media screen and (min-width: 768px) {
            width: 138px;
            height: 138px;
        }
    }
    
    .barber-data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 16px;
        
        @media screen and (min-width: 768px) {
            padding-left: 30px;
        }

        .name {
            font-size: 20px;
            font-family: "Indivisible-Bold";
            line-height: 1.2em;

            @media screen and (min-width: 768px) {
                font-size: 28px;
            }
        }

        .job {
            font-size: 20px;
            font-family: "Indivisible-Regular";
            line-height: 1.2em;

            @media screen and (min-width: 768px) {
                font-size: 28px;
            }
        }
    }
}