@import "../../styles/fonts.scss";

.card {
    display: flex;
    flex-direction: column;
    border: 1px solid white;
    padding: 20px;
    flex: 1;
    gap: 10px;

    @media screen and (min-width: 768px) {
        gap: 20px;
    }

    .title {
        font-size: 20px;
        font-family: Arial, Helvetica, sans-serif;
        margin-bottom: 10px;
        padding-bottom: 20px;
        border-bottom: 1px solid white;

        @media screen and (min-width: 768px) {
            font-size: 24px;
        }
    }

    .price {
        font-size: 16px;
        font-family: Arial, Helvetica, sans-serif;
        margin-bottom: 10px;
        color: #ffffff;
        text-align: left;

        @media screen and (min-width: 768px) {
            font-size: 20px;
        }
    }

    .button {
        font-family: "Indivisible-Regular";
        background-color: #fff;
        text-decoration: none;
        color: #000;
        padding: 10px 20px;
        margin-top: auto;
        border: none;
        cursor: pointer;
        max-width: 100px;
        transition: background-color 0.3s ease, color 0.3s ease;
    }

    .button:hover {
        background-color: #000;
        color: #fff;
        border: 1px solid #fff;
    }
}