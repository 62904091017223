@import "../../styles/fonts.scss";

.footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 80px 40px 80px;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        padding: 30px 100px 60px 100px;
        gap: 40px;
    }

    .working-hours {
        display: flex;
        flex-direction: column;
        
        .title {
            font-family: "Indivisible-Bold";
            font-size: 37px;
            line-height: 1.1em;
            text-align: center;
        }

        .hours {
            display: flex;
            flex-direction: column;
            align-items: center;
            
            @media screen and (min-width: 768px) {
                align-items: flex-start;
            }

            .item {
                font-family: "Indivisible-Regular";
                font-size: 16px;
            }
        }
    }

    .location {
        .title {
            font-family: "Indivisible-Bold";
            font-size: 37px;
            line-height: 1.1em;
            text-align: center;
        }

        .address {
            display: flex;
            flex-direction: column;
            align-items: center;
            
            @media screen and (min-width: 768px) {
                align-items: flex-start;
            }

            .full-adress-name {
                text-decoration: none;
                font-family: "Indivisible-Regular";
                color: #000000;
                font-size: 16px;
            }

            .item {
                font-family: "Indivisible-Regular";
                font-size: 16px;
            }
        }

        .info-text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 20px;

            .text-en {
                font-family: "Indivisible-Bold";
                font-size: 16px;
            }

            .text {
                font-size: 16px;
            }
        }
    }
}