@import "../../styles/fonts.scss";

.intro {
    padding: 20px 50px;
    
    @media screen and (min-width: 768px) {
        padding: 40px 100px;
    }

    .title {
        font-family: "Indivisible-Bold";
        font-size: 36px;
        text-align: center;
        margin: 0;

        @media screen and (min-width: 768px) {
            font-size: 50px;
        }
    }
    
    .subtitle {
        font-family: "Indivisible-Bold";
        font-size: 16px;
        text-align: center;
        margin-bottom: 0;

        @media screen and (min-width: 768px) {
            font-size: 16px;
        }
    }
}
