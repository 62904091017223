@import "../../styles/fonts.scss";

.services-section {
    display: flex;
    flex-direction: column;
    padding: 20px 50px;
    background-color: #000000;
    
    @media screen and (min-width: 768px) {
        padding: 40px 100px;
    }

    .title {
        font-size: 38px;
        font-family: "Indivisible-Bold";
        text-align: left;
        color: #FFFFFF;
        margin-bottom: 20px;
    }

    .cards {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        
        @media screen and (min-width: 768px) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 40px;
        }
    }

    .message {
        font-size: 14px;
        line-height: 16px;
        font-family: Arial, Helvetica, sans-serif;
        text-align: left;
        color: #FFFFFF;
        margin-top: 20px;
    }
}